<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters justify="end" align="center">
      <!-- <smart-breadcrumbs :items="navigationElements" /> -->
      <help class="help--breadcrumb" :helpId="35" />
    </v-row>
    <horizontal-menu class="mt-1" :menuItems="menuItems" />

    <calendar-assigned-evaluations />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  // import SingleMetric from '@/components/Stats/SingleMetric'
  import CalendarAssignedEvaluations from '@/components/SupervisionControl/CalendarAssignedEvaluations'
  import { decryptData } from '@/utils/encryption'

  export default {
    components: {
      HorizontalMenu,
      CalendarAssignedEvaluations,
      // SingleMetric,
    },
    data: () => ({
      loading: false,
      stats: {},
      menuItems: [
        {
          id: 3,
          name: 'Evaluaciones Asignadas',
          icon: 'mdi-text-box-multiple-outline',
          link: '/business/supervision-control/company/assigned-evaluations',
        },
        {
          id: 4,
          name: 'Mis documentos',
          icon: 'mdi-text-box-multiple-outline',
          link: '/business/supervision-control/company/documents',
        },
      ],
    }),
    created() {
      this.getStats()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      getStats() {
        this.loading = true
        this.$axios
          .get(
            'stats-management/control-and-supervision-user-stats?company_id=' +
              this.company.id
          )
          .then((response) => {
            this.stats = response.data
            this.loading = false
          })
      },
    },
  }
</script>
